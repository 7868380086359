import React, { ReactNode } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useCDNUrl, useWriteKey } from "../hooks/useConfig";

const AnalyticsContext = React.createContext<
  | {
      analytics: AnalyticsBrowser;
      writeKey: string;
      setWriteKey: (key: string) => void;
      cdnURL: string;
      setCDNUrl: (url: string) => void;
    }
  | undefined
>(undefined);

export function AnalyticsProvider({ children }: { children: ReactNode }) {
  const [writeKey, setWriteKey] = useWriteKey();
  const [cdnURL, setCDNUrl] = useCDNUrl();

  const analytics = React.useMemo(() => {
    return AnalyticsBrowser.load({ writeKey: writeKey as string, cdnURL });
  }, [writeKey, cdnURL]);
  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
        writeKey: writeKey as string,
        setWriteKey,
        cdnURL,
        setCDNUrl,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};
