import { Review } from "./review";

export enum Turnaround {
  TurnaroundUnknown = "unknown",
  Turnaround24 = "24",
  Turnaround24to48 = "24to48",
  Turnaround48to72 = "48to72",
  Turnaround72plus = "72plus",
}

export const TurnaroundSort = {
  [Turnaround.TurnaroundUnknown]: null,
  [Turnaround.Turnaround24]: 24,
  [Turnaround.Turnaround24to48]: 48,
  [Turnaround.Turnaround48to72]: 72,
  [Turnaround.Turnaround72plus]: 73,
};

export const TurnaroundDetails = [
  {
    value: Turnaround.TurnaroundUnknown,
    title: "Unknown",
  },
  {
    value: Turnaround.Turnaround24,
    title: "Within 24 hours",
  },
  {
    value: Turnaround.Turnaround24to48,
    title: "24 to 48 hours",
  },
  {
    value: Turnaround.Turnaround48to72,
    title: "48 to 72 hours",
  },
  {
    value: Turnaround.Turnaround72plus,
    title: "Over 72 hours",
  },
];

export enum SupportedModel {
  SupportedModelWholesale = "wholesale",
  SupportedModelPrivateLabel = "private_label",
  SupportedModelOnlineArbitrage = "online_arbitrage",
  SupportedModelRetailArbitrage = "retail_arbitrage",
  SupportedModelHazmat = "hazmat",
  SupportedModelBooks = "books",
}

export const SupportedModelDetails = [
  {
    value: SupportedModel.SupportedModelWholesale,
    title: "Wholesale",
    description: "High unit volume, often palletized.",
  },
  {
    value: SupportedModel.SupportedModelPrivateLabel,
    title: "Private label",
    description: "High unit volume, often palletized.",
  },
  {
    value: SupportedModel.SupportedModelOnlineArbitrage,
    title: "Online arbitrage",
    description:
      "Variety of products, ordered online and shipped to the center.",
  },
  {
    value: SupportedModel.SupportedModelRetailArbitrage,
    title: "Retail arbitrage",
    description: "Variety of products.",
  },
  {
    value: SupportedModel.SupportedModelHazmat,
    title: "Hazmat",
    description:
      "Dangerous goods that require the seller to have authorization for.",
  },
  {
    value: SupportedModel.SupportedModelBooks,
    title: "Books",
    description: "New and used book prep.",
  },
];

export class PrepCenterLocation {
  id: string;
  prepCenterId: string;
  name: string | null;
  country: string;
  state: string;
  city: string | null;
  postalCode: string | null;
  line1: string | null;
  line2: string | null;
  latitude: number | null;
  longitude: number | null;
  createdBy: string | null;
  created_at: Date;
  updated_at: Date;

  constructor(prepCenterLocation: any) {
    if (!prepCenterLocation) {
      throw new Error("error constructing prep center location");
    }
    this.id = prepCenterLocation?.id;
    this.prepCenterId = prepCenterLocation?.prep_center_id;
    this.name = prepCenterLocation?.name ?? null;
    this.country = prepCenterLocation?.country;
    this.state = prepCenterLocation?.state;
    this.city = prepCenterLocation?.city ?? null;
    this.postalCode = prepCenterLocation?.postal_code ?? null;
    this.line1 = prepCenterLocation?.line1 ?? null;
    this.line2 = prepCenterLocation?.line2 ?? null;
    this.latitude = prepCenterLocation?.latitude ?? null;
    this.longitude = prepCenterLocation?.longitude ?? null;
    this.createdBy = prepCenterLocation?.created_by ?? null;
    this.created_at = new Date(prepCenterLocation?.created_at);
    this.updated_at = new Date(prepCenterLocation?.updated_at);
  }
}

export class PrepCenter {
  id: string;
  name: string;
  acceptingClients: boolean | null;
  ownerId: string | null;
  tagline: string | null;
  description: string | null;
  logo: string | null;
  banner: string | null;
  images: string[] | null;
  website: string | null;
  phone: string | null;
  email: string | null;
  turnaround: Turnaround | null;
  supportedModels: SupportedModel[] | null;
  liftgateRequired: boolean | null;
  linkedin: string | null;
  facebook: string | null;
  instagram: string | null;
  twitter: string | null;
  youtube: string | null;
  verified: boolean;
  createdBy: string | null;
  created_at: Date;
  updated_at: Date;
  locations: PrepCenterLocation[] | null;
  reviewCount: number | null;
  averageRating: number | null;
  reviews: Review[] | null;

  constructor(prepCenter: any) {
    if (!prepCenter) {
      throw new Error("error constructing prep center");
    }
    this.id = prepCenter?.id;
    this.name = prepCenter?.name;
    this.acceptingClients = prepCenter?.accepting_clients ?? null;
    this.ownerId = prepCenter?.owner_id ?? null;
    this.tagline = prepCenter?.tagline ?? null;
    this.description = prepCenter?.description ?? null;
    this.logo = prepCenter?.logo ?? null;
    this.banner = prepCenter?.banner ?? null;
    this.images = prepCenter?.images ?? null;
    this.website = prepCenter?.website ?? null;
    this.phone = prepCenter?.phone ?? null;
    this.email = prepCenter?.email ?? null;
    this.turnaround = (prepCenter?.turnaround as Turnaround) ?? null;
    this.supportedModels =
      prepCenter?.supported_models?.map(
        (model: string) => (model as SupportedModel) ?? null
      ) ?? null;
    this.liftgateRequired = prepCenter?.liftgate_required ?? null;
    this.linkedin = prepCenter?.linkedin ?? null;
    this.facebook = prepCenter?.facebook ?? null;
    this.instagram = prepCenter?.instagram ?? null;
    this.twitter = prepCenter?.twitter ?? null;
    this.youtube = prepCenter?.youtube ?? null;
    this.verified = prepCenter?.verified;
    this.createdBy = prepCenter?.created_by ?? null;
    this.created_at = new Date(prepCenter?.created_at);
    this.updated_at = new Date(prepCenter?.updated_at);
    this.locations =
      prepCenter?.locations?.map(
        (location: any) => new PrepCenterLocation(location)
      ) ?? null;
    this.reviewCount = prepCenter?.review_count ?? null;
    this.averageRating = prepCenter?.average_rating ?? null;
    this.reviews =
      prepCenter?.reviews?.map((review: any) => new Review(review)) ?? null;
  }
}
