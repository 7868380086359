import { Settings } from "./settings";

export class AuthUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  access_token: string;
  theme: string;
  loginWithAmazon: boolean;

  constructor(cognitoUser: any) {
    if (!cognitoUser) {
      throw new Error("error constructing auth user");
    }
    this.id = cognitoUser?.attributes?.sub;
    this.email = cognitoUser?.attributes?.email;
    this.first_name = cognitoUser?.attributes?.given_name;
    this.last_name = cognitoUser?.attributes?.family_name;
    this.name = cognitoUser?.attributes?.name;
    this.access_token = cognitoUser?.signInUserSession?.accessToken?.jwtToken;
    this.theme = cognitoUser?.attributes?.["custom:theme"] ?? "system";
    if (cognitoUser?.attributes?.identities) {
      const identities = JSON.parse(cognitoUser?.attributes?.identities);
      this.loginWithAmazon = identities.some(
        (identity: any) => identity?.providerType === "LoginWithAmazon"
      );
    } else {
      this.loginWithAmazon = false;
    }
  }

  getName() {
    return getName(this.first_name, this.last_name, this.name);
  }

  getFirstLetter() {
    return getFirstLetter(this.getName());
  }
}

export type UserRole = "owner" | "admin";

export class DBUser {
  id: string;
  prep_center_id: string;
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  is_admin: boolean;
  created_at: Date;
  updated_at: Date;
  intercom_user_hash: string | null;

  constructor(dbModel: any) {
    if (!dbModel) {
      throw new Error("error constructing db user");
    }
    this.id = dbModel?.id;
    this.prep_center_id = dbModel?.prep_center_id;
    this.email = dbModel?.email;
    this.first_name = dbModel?.first_name;
    this.last_name = dbModel?.last_name;
    this.name = dbModel?.name;
    this.is_admin = dbModel?.is_admin;
    this.created_at = new Date(dbModel?.created_at);
    this.updated_at = new Date(dbModel?.updated_at);
    this.intercom_user_hash = dbModel?.intercom_user_hash ?? null;
  }

  getName() {
    return getName(this.first_name, this.last_name, this.name);
  }

  getFirstLetter() {
    return getFirstLetter(this.getName());
  }
}

export function getName(firstName: string, lastName: string, name: string) {
  let from = "User";
  if (firstName && firstName.length > 0) {
    from = firstName;
    if (lastName && lastName.length > 0) {
      from += " " + lastName;
    }
  } else if (name && name.length > 0) {
    from = name;
  }

  return from
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

export function getFirstLetter(name: string) {
  return name.charAt(0).toUpperCase();
}
