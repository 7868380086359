import { PrepCenter } from "./prepCenter";

export class Review {
  userId: string;
  prepCenterId: string;
  ipAddress: string | null;
  rating: number;
  review: string | null;
  created_at: Date;
  updated_at: Date;
  reviewerFirstName: string | null;
  reviewerLastName: string | null;
  reviewerName: string | null;
  prepCenter: PrepCenter | null;

  constructor(review: any) {
    if (!review) {
      throw new Error("error constructing review");
    }
    this.userId = review?.user_id;
    this.prepCenterId = review?.prep_center_id;
    this.ipAddress = review?.ip_address ?? null;
    this.rating = review?.rating;
    this.review = review?.review ?? null;
    this.created_at = new Date(review?.created_at);
    this.updated_at = new Date(review?.updated_at);
    this.reviewerFirstName = review?.reviewer_first_name ?? null;
    this.reviewerLastName = review?.reviewer_last_name ?? null;
    this.reviewerName = review?.reviewer_name ?? null;
    this.prepCenter = review?.prep_center
      ? new PrepCenter(review?.prep_center)
      : null;
  }
}
