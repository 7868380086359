const amplifyConfig = {
  Auth: {
    region: process.env.NEXT_PUBLIC_AWS_REGION,
    userPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId:
      process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_DOMAIN,
      redirectSignIn: process.env.NEXT_PUBLIC_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.NEXT_PUBLIC_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain:
        process.env.NODE_ENV === "development" ? "localhost" : "prepcenter.com",
      path: "/",
      expires: 365,
      sameSite: "lax",
      secure: false,
    },
  },
  ssr: true,
};

export default amplifyConfig;
