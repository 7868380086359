import "../styles/globals.css";
import "flag-icons/css/flag-icons.min.css";
import type { AppProps } from "next/app";
import { useEffect } from "react";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import { NextSeo } from "next-seo";
import { AuthProvider } from "../hooks/auth";
import { ThemeProvider } from "next-themes";
import { AnalyticsProvider } from "../hooks/analytics";
import Head from "next/head";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faPhone,
  faShieldCheck,
  faCircle,
} from "@fortawesome/pro-solid-svg-icons";
// import { far } from "@fortawesome/pro-regular-svg-icons";
// import { fal } from "@fortawesome/pro-light-svg-icons";
// import { fat } from "@fortawesome/pro-thin-svg-icons";
import {
  faPartyHorn,
  faSparkles,
  faCopy,
  faWarehouseFull,
  faMap,
  faMapLocationDot,
  faForklift,
  faList,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faYoutube,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";

import SeoMeta from "../public/meta.json";
import { useRouter } from "next/router";

// Fontawesome
config.autoAddCss = false;
library.add(
  faPhone,
  faFacebook,
  faYoutube,
  faInstagram,
  faTwitter,
  faLinkedin,
  faPartyHorn,
  faSparkles,
  faCircleCheck,
  faShieldCheck,
  faCopy,
  faWarehouseFull,
  faMap,
  faMapLocationDot,
  faCircle,
  faForklift,
  faList,
  faDiscord
);

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const { pathname } = router;
  const getLayout = Component.getLayout ?? ((page) => page);

  var seoMeta = SeoMeta as { [index: string]: any };

  useEffect(() => {
    document.body.className = pageProps.overflowHidden
      ? "h-full overflow-hidden"
      : "";
  });

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#086efa" />
        <meta name="msapplication-TileColor" content="#086efa" />
        <meta name="theme-color" content="#086efa" />
      </Head>
      <NextSeo
        additionalMetaTags={[
          {
            name: "title",
            content: seoMeta[pathname]?.metaTitle ?? seoMeta["/"].metaTitle,
          },
        ]}
        description={
          seoMeta[pathname]?.metaDescription ?? seoMeta["/"].metaDescription
        }
        additionalLinkTags={[
          {
            rel: "icon",
            href: "/favicon.svg",
          },
          {
            rel: "manifest",
            href: "/manifest.json",
          },
        ]}
        openGraph={{
          type: "website",
          url: `https://prepcenter.com${pathname}`,
          title: seoMeta[pathname]?.title ?? seoMeta["/"].title,
          images:
            seoMeta[pathname]?.images && seoMeta[pathname]?.images.length > 0
              ? seoMeta[pathname].images
              : seoMeta["/"].images,
        }}
      />

      <ThemeProvider attribute="class" defaultTheme="light" forcedTheme="light">
        <AnalyticsProvider>
          <AuthProvider>{getLayout(<Component {...pageProps} />)}</AuthProvider>
        </AnalyticsProvider>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
