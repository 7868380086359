import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Amplify, Auth, Hub } from "aws-amplify";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import amplifyConfig from "../config/amplify";
import { PrepCenter } from "../model/prepCenter";
import { AuthUser, DBUser } from "../model/user";
import callBackend from "../shared/backend";
import { StartOAuthFlow } from "../shared/oauth";
import { NewRandomPassword } from "../shared/password";
import { useAnalytics } from "./analytics";

// Note: must be a global var so Amplify is configured once
let amplifyConfigured = false;

const AuthContext = createContext<{
  authUser: AuthUser | null;
  dbUser: DBUser | null;
  prepCenter: PrepCenter | null;
  signIn: (email: string, password: string) => void;
  loginWithAmazon: (signin?: boolean) => void;
  signUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => void;
  resendSignUp: (email: string) => void;
  confirmSignUpAndSignIn: (
    email: string,
    password: string,
    code: string
  ) => void;
  logout: () => void;
  forgotPassword: (email: string) => void;
  forgotPasswordSubmitAndSignIn: (
    email: string,
    code: string,
    newPassword: string
  ) => void;
  loginWithAmazonError: string | null;
  updateAttribute: (name: string, value: string) => void;
  support?: boolean;
  updateUser: ({}: {
    user_first_name?: string;
    user_last_name?: string;
  }) => Promise<string | undefined> | void;
  userError: string | null;
  invitationCode: string | null;
  configured: boolean;
  disableLoginWithAmazon: () => Promise<string | undefined> | void;
  setCurrentUser: (bypassCache: boolean) => Promise<void> | void;
  changePassword: (
    oldPassword: string,
    newPassword: string
  ) => Promise<string | undefined> | void;
  updateEmail: (newEmail: string) => Promise<string | undefined> | void;
  getOrCreateUser: (force: boolean) => Promise<void> | void;
}>({
  authUser: null,
  dbUser: null,
  prepCenter: null,
  signIn: () => {},
  loginWithAmazon: () => {},
  signUp: () => {},
  resendSignUp: () => {},
  confirmSignUpAndSignIn: () => {},
  logout: () => {},
  forgotPassword: () => {},
  forgotPasswordSubmitAndSignIn: () => {},
  loginWithAmazonError: null,
  updateAttribute: () => {},
  support: false,
  updateUser: () => {},
  userError: null,
  invitationCode: null,
  configured: false,
  disableLoginWithAmazon: () => {},
  setCurrentUser: () => {},
  changePassword: () => {},
  updateEmail: () => {},
  getOrCreateUser: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const [cookies, setCookie, removeCookie] = useCookies([
    "prepCenterId",
    "invitationCode",
  ]);
  const [cognitoUser, setCognitoUser] = useState<any>();
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const [dbUser, setDbUser] = useState<DBUser | null>(null);
  const [prepCenter, setPrepCenter] = useState<PrepCenter | null>(null);
  const [loginWithAmazonError, setLoginWithAmazonError] = useState<
    string | null
  >(null);
  const [userError, setUserError] = useState<string | null>(null);
  const [configured, setConfigured] = useState(false);
  const {
    analytics: analyticsBrowser,
    cdnURL,
    setCDNUrl,
    writeKey,
    setWriteKey,
  } = useAnalytics();

  useEffect(() => {
    if (!amplifyConfigured) {
      Amplify.configure(amplifyConfig);
      amplifyConfigured = true;
      setConfigured(true);
    }
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          router.push("/");
          break;
        case "signOut":
          router.push("/signin");
          break;
        case "cognitoHostedUI_failure":
          let message = "Error using Login with Amazon.";
          if (data instanceof Error) {
            message = decodeURIComponent(data.message.replace(/\+/g, "%20"));
            if (message.indexOf(";") > 0) {
              message = message.substring(0, message.indexOf(";"));
            }
            message = message.split("PreSignUp failed with error ")[1];
          }
          setLoginWithAmazonError(message);
          break;
      }
    });

    return unsubscribe;
  }, [setConfigured, router]);

  const setCurrentUser = useCallback(
    async (bypassCache?: boolean) => {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: bypassCache as boolean,
        });
        setCognitoUser(user);
        setAuthUser(new AuthUser(user));
      } catch (error) {
        console.log(error);
      }
    },
    [setCognitoUser, setAuthUser]
  );

  useEffect(() => {
    setCurrentUser();
  }, [setCurrentUser, router]);

  const getOrCreateUser = useCallback(
    async (force: boolean) => {
      setUserError(null);
      if (
        authUser &&
        (force ||
          !dbUser ||
          (prepCenter && !document.cookie.includes(prepCenter.id)))
      ) {
        try {
          // Get User and Prep Center if one is managed
          const response = await callBackend({
            uri: "/users",
            userId: authUser.id,
            accessToken: authUser.access_token,
          });
          if (response.ok) {
            const data = await response.json();
            setDbUser(new DBUser(data));
            setPrepCenter(
              data?.prep_center ? new PrepCenter(data?.prep_center) : null
            );
            setCookie("prepCenterId", data?.prep_center_id, { path: "/" });
          } else {
            if (response.status === 404) {
              const errorText = await response.text();
              if (errorText.includes("user")) {
                // If User not found, create one
                const data: any = {
                  user_id: authUser.id,
                  user_email: authUser.email,
                  user_first_name: authUser.first_name,
                  user_last_name: authUser.last_name,
                  user_name: authUser.name,
                };
                if (cookies.invitationCode) {
                  data.invitation_code = cookies.invitationCode;
                }
                const response = await callBackend({
                  uri: "/users",
                  method: "POST",
                  userId: authUser.id,
                  accessToken: authUser.access_token,
                  data,
                });
                if (response.ok) {
                  const data = await response.json();
                  setDbUser(new DBUser(data));
                  setPrepCenter(
                    data?.prep_center ? new PrepCenter(data?.prep_center) : null
                  );
                  setCookie("prepCenterId", data?.prep_center_id, {
                    path: "/",
                  });
                  removeCookie("invitationCode", { path: "/" });
                } else {
                  const errorText = await response.text();
                  console.log(response.status, errorText);
                  setUserError(`Error creating user: ${errorText}`);
                  // TODO: Fallback to some sort of Sentry error
                }
              } else if (errorText.includes("prep center")) {
                // If Prep Center not found, remove cookie and try again
                removeCookie("prepCenterId");
              } else {
                const errorText = await response.text();
                console.log(response.status, errorText);
                setUserError(`Error retrieving user: ${errorText}`);
                // TODO: Fallback to some sort of Sentry error
              }
            } else {
              const errorText = await response.text();
              console.log(response.status, errorText);
              setUserError(`Error retrieving user: ${errorText}`);
              // TODO: Fallback to some sort of Sentry error
            }
          }
        } catch (error) {
          console.log(error);
          if (error instanceof Error) {
            setUserError(`Error creating or retrieving user: ${error.message}`);
          }
          // TODO: Fallback to some sort of Sentry error
        }
      }
    },
    [
      authUser,
      dbUser,
      prepCenter,
      cookies,
      setCookie,
      removeCookie,
      setDbUser,
      setPrepCenter,
      setUserError,
    ]
  );

  useEffect(() => {
    getOrCreateUser(false);
  }, [router, getOrCreateUser]);

  const signUp = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
        },
      });
    },
    []
  );

  const resendSignUp = useCallback(async (email: string) => {
    await Auth.resendSignUp(email);
  }, []);

  const invitationCode = useMemo(() => {
    if (router.asPath.includes("/invite")) {
      const codeArray = router.asPath.split("/invite/");
      if (codeArray.length == 2) {
        return codeArray[1];
      }
    }
    return null;
  }, [router]);

  const saveInvitationCode = useCallback(async () => {
    if (invitationCode && invitationCode.length > 0) {
      setCookie("invitationCode", invitationCode, { path: "/" });
    } else {
      removeCookie("invitationCode", { path: "/" });
    }
  }, [invitationCode, setCookie, removeCookie]);

  const confirmSignUpAndSignIn = useCallback(
    async (email: string, password: string, code: string) => {
      await Auth.confirmSignUp(email, code);
      await saveInvitationCode();
      await Auth.signIn(email, password);
    },
    [saveInvitationCode]
  );

  const signIn = useCallback(async (email: string, password: string) => {
    setLoginWithAmazonError(null);
    const user = await Auth.signIn(email, password);
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      await Auth.completeNewPassword(user, NewRandomPassword());
    }
  }, []);

  const loginWithAmazon = useCallback(
    async (signin?: boolean) => {
      if (signin) {
        await saveInvitationCode();
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Amazon,
        });
      } else {
        await StartOAuthFlow();
      }
      setLoginWithAmazonError(null);
    },
    [saveInvitationCode, setLoginWithAmazonError]
  );

  const logout = useCallback(async () => {
    setCognitoUser(null);
    setAuthUser(null);
    removeCookie("prepCenterId", { path: "/" });
    removeCookie("invitationCode", { path: "/" });
    await Auth.signOut({ global: true });
  }, [removeCookie, setCognitoUser]);

  const forgotPassword = useCallback(async (email: string) => {
    await Auth.forgotPassword(email);
  }, []);

  const forgotPasswordSubmitAndSignIn = useCallback(
    async (email: string, code: string, newPassword: string) => {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      await Auth.signIn(email, newPassword);
    },
    []
  );

  const updateAttribute = useCallback(
    async (name: string, value: string) => {
      const attributes: { [key: string]: string } = {};
      attributes[name] = value;
      await Auth.updateUserAttributes(cognitoUser, attributes);
      await setCurrentUser();
    },
    [cognitoUser, setCurrentUser]
  );

  const support = useMemo(() => {
    if (dbUser && prepCenter) {
      return dbUser.is_admin && dbUser.prep_center_id !== prepCenter.id;
    }
  }, [dbUser, prepCenter]);

  const updateUser = useCallback(
    async (data: { user_first_name?: string; user_last_name?: string }) => {
      if (authUser) {
        const response = await callBackend({
          uri: "/users",
          method: "PATCH",
          userId: authUser.id,
          accessToken: authUser.access_token,
          data,
        });
        if (response.ok) {
          await getOrCreateUser(true);
        } else {
          const errorText = await response.text();
          return errorText;
        }
      }
    },
    [authUser, getOrCreateUser]
  );

  const disableLoginWithAmazon = useCallback(async () => {
    const response = await fetch("/api/disableLwa");
    if (response.ok) {
      setCurrentUser(true);
    } else {
      const errorText = await response.text();
      return errorText;
    }
  }, [setCurrentUser]);

  const changePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      try {
        await Auth.changePassword(cognitoUser, oldPassword, newPassword);
      } catch (error) {
        if (error instanceof Error) {
          return error.message;
        }
      }
    },
    [cognitoUser]
  );

  const updateEmail = useCallback(
    async (newEmail: string) => {
      try {
        const response = await fetch("/api/updateEmail", {
          method: "POST",
          body: JSON.stringify({
            email: newEmail,
          }),
        });
        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }
        await setCurrentUser(true);
        await getOrCreateUser(true);
      } catch (error) {
        if (error instanceof Error) {
          return error.message;
        }
      }
    },
    [setCurrentUser, getOrCreateUser]
  );

  useEffect(() => {
    async function identify() {
      if (dbUser) {
        const traits: {
          application: string;
          createdAt: Date;
          email: string;
          firstName: string;
          lastName: string;
          name?: string | undefined;
          prepCenterId?: string | undefined;
          company?:
            | {
                id: string;
                name: string;
              }
            | undefined;
        } = {
          application: "prepcenter",
          createdAt: dbUser.created_at,
          email: dbUser.email,
          firstName: dbUser.first_name,
          lastName: dbUser.last_name,
          name: dbUser.name,
        };

        // Set prep center data if one is claimed by user
        if (dbUser.prep_center_id) {
          traits.prepCenterId = dbUser.prep_center_id;

          if (prepCenter) {
            traits.company = {
              id: dbUser.prep_center_id,
              name: prepCenter.name,
            };
          }
        }

        await analyticsBrowser.identify(dbUser.id, traits, {
          Intercom: {
            user_hash: dbUser.intercom_user_hash,
          },
        });
      }
    }
    identify();
  }, [dbUser, analyticsBrowser, prepCenter]);

  useEffect(() => {
    async function page() {
      await analyticsBrowser.page();
    }
    page();
  }, [router, analyticsBrowser]);

  return (
    <AuthContext.Provider
      value={{
        authUser,
        dbUser,
        prepCenter,
        signUp,
        resendSignUp,
        confirmSignUpAndSignIn,
        signIn,
        loginWithAmazon,
        logout,
        forgotPassword,
        forgotPasswordSubmitAndSignIn,
        loginWithAmazonError,
        updateAttribute,
        support,
        updateUser,
        userError,
        invitationCode,
        configured,
        disableLoginWithAmazon,
        setCurrentUser,
        changePassword,
        updateEmail,
        getOrCreateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
