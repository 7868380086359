export default async function callBackend({
  uri = "",
  method = "GET",
  data = {},
  userId = "",
  prepCenterId = "",
  accessToken = "",
}) {
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
    "User-ID": userId,
  };
  if (prepCenterId.length > 0) {
    headers["Prep-Center-ID"] = prepCenterId;
  }
  return await fetch(process.env.NEXT_PUBLIC_API_ENDPOINT + uri, {
    method,
    headers: headers,
    body: method === "GET" ? undefined : JSON.stringify(data),
  });
}
